<template>
  <section
    id="centers-table"
    class="cluster-table"
    :class="{ card: showSimilar }"
  >
    <vs-popup title="Word cloud" :active.sync="showWordCloud">
      <ClustersWordCloud :data="wordCloudData" v-if="wordCloudData" />
    </vs-popup>

    <vs-table
      v-if="clusterCenters"
      :cluster-centers="clusterCenters"
      :data="clusterCenters"
      search
      :sst="true"
      @search="handleSearch"
      @sort="handleSort"
      ref="table"
      :key="key"
      :no-data-text="lang.general.noDataText[languageSelected]"
    >
      <!-- :max-items="LIMIT"
            @change-page="handleChangePage"
            pagination
            :total="total" -->
      <template slot="thead">
        <vs-th sort-key="cluster_name">
          {{ lang.clustering.centersTable.clusterName[languageSelected] }}
        </vs-th>
        <vs-th sort-key="total_samples">
          {{ lang.clustering.centersTable.totalSamples[languageSelected] }}
        </vs-th>
        <vs-th v-if="showSimilar">
          {{ lang.clustering.centersTable.similarIntents[languageSelected] }}
        </vs-th>
        <vs-th>
          {{ lang.clustering.centersTable.actions[languageSelected] }}
        </vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="tr.cluster_name">
            {{ tr.cluster_name }}
          </vs-td>
          <vs-td :data="tr.total_samples">
            {{ tr.total_samples }}
          </vs-td>
          <vs-td :data="tr.similar_intents" v-if="showSimilar">
            <vs-chip
              v-for="(intent, intentIndex) in tr.similar_intents"
              :key="intentIndex"
            >
              {{ intent }}
            </vs-chip>
          </vs-td>
          <vs-td class="actions">
            <!-- <vx-tooltip color="dark" text="See word cloud"> -->
            <vs-button
              :title="
                lang.clustering.centersTable.actionsTooltips.showWordCloud[
                  languageSelected
                ]
              "
              radius
              color="primary"
              type="flat"
              icon="icon-cloud"
              icon-pack="feather"
              size="small"
              @click.stop="openWordCloud(indextr)"
            />
            <!-- </vx-tooltip> -->
          </vs-td>

          <template class="expand-centers" slot="expand">
            <div
              class="con-expand-centers bg-transparent p-0 pt-4 pb-base center"
            >
              <div class="center__samples">
                <p class="title">
                  {{ lang.clustering.centersTable.samples[languageSelected] }}
                </p>
                <div class="items">
                  <vs-chip
                    class="mr-2 sample-chip"
                    color="rgba(var(--vs-primary), .9)"
                    v-for="(sample, sampleIndex) in tr.samples"
                    :key="sampleIndex"
                  >
                    <vx-tooltip :text="sample" position="top">
                      {{ sample }}
                    </vx-tooltip>
                  </vs-chip>
                </div>
              </div>

              <div class="center__actions">
                <vs-button
                  :title="
                    lang.clustering.centersTable.downloadSamples[
                      languageSelected
                    ]
                  "
                  radius
                  color="primary"
                  type="flat"
                  icon="icon-download"
                  icon-pack="feather"
                  size="small"
                  :disabled="!tr.samples"
                  @click="downloadSamples(tr.samples)"
                />
              </div>
            </div>
          </template>
        </vs-tr>
      </template>
    </vs-table>
  </section>
</template>

<script>
import { mapState } from 'vuex'
const Json2csvParser = require('json2csv').Parser

export default {
  name: 'CentersTable',
  props: {
    clusterCenters: {
      type: Array,
      required: true
    },
    showSimilar: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      wordCloudData: null,
      showWordCloud: false,
      key: new Date().getTime(),
      Json2csvParser
    }
  },
  components: {
    ClustersWordCloud: () => import('../word-cloud/ClustersWordCloud.vue'),
    VsTable: () => import('@/components/vx-table/vsTable'),
    VsTh: () => import('@/components/vx-table/vsTh'),
    VsTr: () => import('@/components/vx-table/vsTr'),
    VsTd: () => import('@/components/vx-table/vsTd')
  },
  computed: {
    ...mapState(['lang', 'languageSelected'])
  },
  methods: {
    handleSearch() {/* handleSearch */},
    handleSort() {/* handleSort */},
    openWordCloud(rowId) {
      this.wordCloudData = this.clusterCenters[rowId].top_words
      this.showWordCloud = true
    },
    downloadSamples(samples) {
      function saveData(blob, fileName) {
        var a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'
        var url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = fileName
        a.click()
        window.URL.revokeObjectURL(url)
      }

      const fields = ['Sample']

      let data = []
      samples.forEach(sample => {
        data.push({
          Sample: sample
        })
      })

      const json2csvParser = new Json2csvParser({ fields })
      const csv = json2csvParser.parse(data)

      let filename = `konecta_centers_${this.clusterId}_`
      filename += new Date().toISOString()

      var BOM = '\uFEFF'
      var csvData = BOM + csv

      var blob = new Blob([csvData], {
        type: 'text/plain; encoding=utf-8'
      })

      saveData(blob, `${filename}.csv`)
    }
  }
}
</script>

<style lang="scss">
.cluster-table {
  &.card {
    margin: 30px 0px;
    padding: 20px;
    background-color: #fafafa;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }

  .vs-table--tr {
    cursor: pointer;
  }

  .center {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    margin-left: 60px;
    margin-right: 60px;
    border-top: solid 1px #ececec;

    .actions span {
      display: flex;
    }

    &__samples {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .title {
        font-size: 1rem;
        margin-bottom: 10px;
        // border-bottom: solid 1px #ececec;
      }

      .items {
        display: flex;
        flex-grow: 1;
        flex-wrap: wrap;
        margin-right: 40px;
        max-height: 140px;
        overflow-y: auto;

        .sample-chip {
          min-width: 100px;
          max-width: 180px;
          max-height: 35px;
          span {
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
          }
        }
      }
    }

    &__actions {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 20px;
    }
  }
}
</style>
